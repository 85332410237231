<template>
  <div class="products">
    <div class="page-header">
      <h1>{{ $t('Marketing Management')}} > {{ $t('Special Day') }}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Special Day') }}</h2>
        <el-button class="action-button" type="primary">{{ $t('Create') }}</el-button>
      </div>

      <div class="promotions-list">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">{{ $t('Special Day Date') }}</th>
              <th scope="col">{{ $t('Discount') }}</th>
              <th scope="col">{{ $t('Actions') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>2023-12-31</td>
              <td>5%</td>
              <td>
                <div class="action-links">
                  <a class="action-link" href="#">
                    {{ $t('Edit') }}
                  </a>
                </div>
              </td>
            </tr>
            <tr>
              <td>2024-02-14</td>
              <td>1%</td>
              <td>
                <div class="action-links">
                  <a class="action-link" href="#">
                    {{ $t('Edit') }}
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ItemFilter from '@/components/Filter.vue';
import Common from '@/lib/common';
import Member from '@/lib/member';

export default {
  name: 'Members',
  components: {
    ItemFilter,
  },
  mounted(){
    this.loadAllMembers();
  },
  methods:{
    async loadAllMembers(){
      try{
        const loginInfo = Common.getLoginInfo();
        const members = await Member.loadAllMembers(this.apiUrl, loginInfo);
        this.members = members;
      }catch(err){
        console.log(err);
      }
    },
  },
  data(){
    return {
      members: [],
      orignalMembers: [],
    }
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
.seat-list{
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
  .card{
    margin-bottom: 30px;
    margin-right: 20px;
    .btn-primary{
      margin-right: 30px;
    }
  }
}
</style>
